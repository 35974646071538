var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar"},[_c('div',{staticClass:"avatar-img",class:{
      'avatar-img--small': _vm.size === 'small',
      'avatar-img--med': _vm.size === 'med',
    }},[(_vm.src)?_c('img',{attrs:{"src":_vm.src,"alt":"user avatar"}}):_c('span',{staticClass:"icon-user avatar-icon",class:{
        'avatar-icon--small': _vm.size === 'small',
        'avatar-icon--med': _vm.size === 'med',
      }})]),_c('span',{staticClass:"avatar-status",class:{
      'avatar-status--small': _vm.size === 'small',
      'avatar-status--med': _vm.size === 'med',
      'avatar-status--offline': _vm.status === 'offline' || !_vm.status,
    }})])}
var staticRenderFns = []

export { render, staticRenderFns }