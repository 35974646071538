<template>
  <div class="avatar">
    <div
      class="avatar-img"
      :class="{
        'avatar-img--small': size === 'small',
        'avatar-img--med': size === 'med',
      }"
    >
      <img v-if="src" :src="src" alt="user avatar" />
      <span
        v-else
        class="icon-user avatar-icon"
        :class="{
          'avatar-icon--small': size === 'small',
          'avatar-icon--med': size === 'med',
        }"
      ></span>
    </div>
    <span
      class="avatar-status"
      :class="{
        'avatar-status--small': size === 'small',
        'avatar-status--med': size === 'med',
        'avatar-status--offline': status === 'offline' || !status,
      }"
    ></span>
  </div>
</template>

<script>
export default {
  props: ["status", "size", "src"],
};
</script>

<style lang="less" scoped>
.avatar {
  position: relative;
  display: inline-block;

  &-img {
    width: 123px;
    height: 123px;
    border-radius: 50%;
    // overflow: hidden;
    position: relative;
    overflow: hidden;

    img {
      width: 123px;
      height: 123px;
      object-fit: cover;
      overflow: hidden;
      border-radius: 50%;
    }

    &--small {
      width: 25px;
      height: 25px;

      img {
        width: 25px;
        height: 25px;
      }
    }

    &--med {
      width: 36px;
      height: 36px;
      border: solid 1px var(--base-color);

      img {
        width: 35px;
        height: 35px;
      }
    }
  }

  &-icon {
    font-size: 85px;
    border: 1px solid #f5f5f5;
    display: inline-block;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    color: #f5f5f5;
    text-align: center;
    position: relative;

    &::before {
      display: inline-block;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &--small {
      font-size: 12px;
    }

    &--med {
      font-size: 22px;
      border: none;
    }
  }

  &-status {
    display: inline-block;
    width: 18px;
    height: 18px;
    border-radius: 50%;
    background-color: #65ff00;
    border: solid 2px #fff;
    position: absolute;
    top: 7px;
    right: 7px;

    &--offline {
      background-color: #f30538;
    }

    &--med {
      width: 10px;
      height: 10px;
      top: 0;
      right: -2px;
    }

    &--small {
      width: 10px;
      height: 10px;
      top: -2px;
      right: -3px;
    }
  }
}
</style>
