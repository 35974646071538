<template>
  <div class="practice">
    <div class="practice__title">{{ $t("Practice.title") }}</div>

    <loader v-if="loader" center />
    <div v-else-if="!items || !items.length" class="absolute-center">{{ $t("Practice.noVirtualRooms") }}</div>
    <div v-else class="practice__list">
      <a
        v-for="item in items"
        v-show="item.link"
        :key="item.link + item.manager"
        :href="item.link"
        target="_blank"
        class="practice__item"
        @click.prevent="onItemClick(item.link)"
      >
        <avatar
          size="small"
          :src="item.manager_photo"
          :status="item.manager_online ? 'online' : 'offline'"
        />
        <span v-line-clamp="2" class="practice__name">{{ item.manager }}</span>
        <ws-icon class="practice__icon" xxl light>chevron-right</ws-icon>
      </a>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Avatar from "@/components/event/room/Dashboard/components/common/Avatar";
import Loader from "@/components/common/elements/Loader";
import { room, webex } from "@/store/modules/store.namespaces";
import { GET_WEBEX_USERS, WEBEX_LOG } from "@/store/modules/webex/action-types";
import WsIcon from "@/components/base/WsIcon";

export default {
  name: "Practice",
  components: { WsIcon, Avatar, Loader },
  data() {
    return {
      loader: false,
    };
  },
  computed: {
    ...mapState(webex, {
      items: "webexUsers",
    }),
    ...mapState(room, ["roomNumber"]),
  },
  watch: {
    roomNumber(val) {
      if (val) {
        this.$nextTick(this.init);
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(webex, {
      webexLog: WEBEX_LOG,
      getWebexUsers: GET_WEBEX_USERS,
    }),
    onItemClick(link) {
      this.webexLog();
      window.open(link, "_blank");
    },
    async init() {
      try {
        this.loader = true;
        await this.getWebexUsers()
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.practice {
  flex-shrink: 0;
}

.practice__title {
  padding-top: 10px;
  padding-bottom: 20px;
  font-size: 18px;
  font-weight: 300;
  text-align: center;
}

.practice__list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-right: 10px;
  padding-bottom: 40px;
  padding-left: 10px;
  margin: 0 auto;
}

.practice__name {
  margin-left: 20px;
  font-weight: 300;
}

.practice__item {
  position: relative;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 440px;
  height: 60px;
  padding-right: 30px;
  padding-left: 10px;
  margin-right: 20px;
  margin-left: 20px;
  overflow: hidden;
  font-size: 1em;
  line-height: 1.3em;
  color: #000;
  text-decoration: none;
  cursor: pointer;
  user-select: none;
  background-color: #ffffff;
  border: solid 1px rgba(12, 156, 255, 0.2);
  border-radius: 4px;

  &:hover {
    border: solid 1px rgba(12, 156, 255, 0.6);
  }

  &:not(:last-of-type) {
    margin-bottom: 12px;
  }
}

.practice__icon::before {
  position: absolute;
  top: 50%;
  right: 10px;
  color: rgba(12, 156, 255, 0.6);
  transition: all ease 0.3s;
  transform: translateY(-50%);
}
</style>
